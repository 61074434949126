import React from 'react'
import Layout from '../components/layout';
import { Link, graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ThankYouPage = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <section className="c-block c-block--first">
                <div className="site-width">

                </div>
       
                <div className="site-width page-title">
                    <h1>{t(`ThankYou`)}</h1>
                    <p className="text-content">{t(`ThankYouDescription`)}</p>
                    <Link to="/" className="text-content">{t(`BackToHome`)}</Link>
                </div>

            </section>
        </Layout>
    )
}

export default ThankYouPage;

export const query = graphql`
query ($language: String!){
        locales: allLocale(filter: {language: {eq: $language}}) {
          edges {
            node {
              ns
              data
              language
            }
          }
        }
      }
`